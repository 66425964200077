import './App.css';
import './containers/Header.js';
import Container from 'react-bootstrap/Container';

import { Col, Row } from 'react-bootstrap';
import { Home } from './containers/Home.js';
import { Header } from './containers/Header.js';
import React from 'react';
import { Route, Routes, BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Container className="mx-auto" style={{ paddingTop: "5px", maxWidth: '60rem' }}>
        <Row >
          <Col xs={{ span: 12 }} >
            <Header />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 12 }}>
            <Routes>
              <Route>
                <Route path="/" element={<Home />} />
              </Route>
            </Routes>
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  );
}



export default App;
