import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Key, PiggyBank, BarChart } from "react-bootstrap-icons";

export const Home = () => (
    <Container style={{/* background: "yellow"*/ }}>
        <Row >
            <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} style={{ marginBottom: "10px" }}>
                <CardAccounting />
            </Col>

            <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} style={{ marginBottom: "10px" }}>
                <CardPricePlans />
            </Col>

            <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 4 }} style={{ marginBottom: "10px" }}>
                <CardLogin />
            </Col>
        </Row>
    </Container>
);

export function CardAccounting() {
    return (
        <Card style={{ minWidth: '18rem', maxWidth: '32rem', height: "100%" }}>
        <BarChart color="#41b6e6" size="6rem" style={{ padding: '1rem' }} />
        <Card.Body className="d-flex flex-column">
          <Card.Title>Simplifies financial administration</Card.Title>
            Vatzon gives you control and simplifies your organizations financial administration.
          <Card.Text>
            
          </Card.Text>

        </Card.Body>
      </Card >
    );
}


export function CardPricePlans() {
    return (
        <Card style={{ minWidth: '18rem', maxWidth: '32rem', height: "100%" }}>
            <PiggyBank color="#41b6e6" size="6rem" style={{ padding: '1rem' }} />

            <Card.Body className="d-flex flex-column">
                <Card.Title>Price plans</Card.Title>
                <Card.Text>
                    Select or upgrade to a price plan with the best fit for your organization.
                </Card.Text>
            </Card.Body>
        </Card>
    );
}


export function CardLogin() {
    return (
        <Card style={{ minWidth: '18rem', maxWidth: '60rem', height: "100%" }}>
            <Key color="#41b6e6" size="6rem" style={{ padding: '1rem' }} />
            <Card.Body className="d-flex flex-column">
                <Card.Title>Get started</Card.Title>
                <Card.Text>
                    When available in your market, an account may be created to get access to the services.
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

