import vatzon_logo_vatzon_blue_on_white from "../resources/vatzon_logo_vatzon_blue_on_white.png";
import vatzon_logo_va_blue_on_white from "../resources/vatzon_logo_va_blue_on_white.png";

import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


export function Header() {
    return (

        <Container className="justify-content-between" style={{ width: "100%", backgroundColor: "white" }}>
            <Row>
                <Col xs={{ span: "12" }} md={{ span: "12" }} lg={{ span: "12" }}>
                    <Container className="justify-content-between" style={{ backgroundColor: "white", float: "left", paddingLeft: "0px" }}>
                        <Navbar collapseOnSelect expand="xs">

                            <Navbar.Brand >
                                <Nav.Link as={Link} to="/" eventKey="1">
                                    <Image className="d-block d-md-none" src={vatzon_logo_va_blue_on_white} height={42} />
                                    <Image className="d-none d-md-block" src={vatzon_logo_vatzon_blue_on_white} height={42} />
                                </Nav.Link>
                            </Navbar.Brand>

                            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ boxShadow: "0 0 0 0", height: "38px", color: '#41b6e6', backgroundColor: '#ffffff', borderColor: '#41b6e6', float: "right" }} />

                            <Navbar.Collapse id="basic-navbar-nav" style={{ marginTop: '10px' }}>
                                <Nav style={{ marginTop: '10px', float: "left", paddingLeft: "10px" }} >
                                    Not available in your market
                                </Nav>
                            </Navbar.Collapse>

                        </Navbar>
                    </Container>
                </Col>
            </Row>
        </Container >

    );
};

export default Header;